/* Eléments principaux de la page */

#apps {
  display: flex;
  margin: 3rem;
  margin-top: 5rem;
    
  & #sections {
    display: flex;
    flex-wrap: wrap;
    font-size: .8em;

    & .section {
      margin: 0rem 1rem 2rem 0rem;
      background: rgba(200,200,255,.1);

      & img {
        max-width: 100px;
      }
    }
  
    & .article
    {
      // background: url('http://blog.usidistrib.be/images/empty_large_tile.png');
      padding: 1rem;
      min-width: 25rem;
      max-width: 25rem;
      height: 15rem;
      color: white;
      border: 3px solid #000024;
      
      &:hover
      {
        border: 3px solid rgba(200,200,255,.1);
      }
    }
  }

  & a
  {
    text-decoration: none;
  }

  & h2
  {
    margin: 0;
    font-weight: normal;
    color: #FFFFAA;
    font-size: 1.28em;
  }

  & .click
  {
    color: #FFFF00;
    margin-top: 10px;
    text-align: right;
  }

  & p
  {
    margin: 0px;
  }

  & .date
  {
    color: #55AAFF;
  }

  & .summary
  {
    margin-top: 10px;
    font-size: 1.1em;
  }

  & .new
  {
    display: block;
    position: relative;
    background-color: #2255FF;
    color: white;
    padding-left: 5px;
    padding-top: 2px;
    width: 30px;
    height: 23px;
    border: solid 1px #0022AA;
    left: 1px;
    top: 10px;
  }

  @media screen and (max-width: 547px) {
    margin: 5rem 1rem;

    & #sections {
      & .article {
        min-width: 200px;
        border-width: 1px;
  
        &:hover {
          border-width: 1px;
        }
      }  
    }
  }
}
