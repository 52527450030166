/* Eléments principaux de la page */

#me {
   margin: 3rem;
   margin-top: 5rem;
   background: rgba(200,200,255,.1);
   padding: 1rem;
   border: 3px solid #000024;

   & a {
      text-decoration: none;

      &:hover {
         font-weight: bold;
      }
   }

   #profil_pic {
   //   position: relative;
      width: 242px;
   //   left: -4%;
      float: left;
      margin-right: 10px;
   }
   
   #infos {
      min-height: 180px;
   }
   
   #infos p {
      margin: 0;
   }
   
   #actions {
      clear: both;
   }

   @media screen and (max-width: 547px) {
      margin: 5rem 1rem;

      #profil_pic {
         float: none;
      }
   }
}
