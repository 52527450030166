/* Eléments principaux de la page */

#sports {

  color: white;
  font-size: .8em;
  margin: 3rem;
  margin-top: 5rem;
  padding: 0rem;

  & .section {
    margin: 1rem 0rem;
    background: rgba(200,200,255,.1);
    min-width: 400px;
    border: 3px solid #000024;

    &:hover
    {
      border: 3px ridge rgba(200,200,255,.2);
    }
  }

  
  & .new
  {
    position: relative;
    left: 1rem;
    top: 1rem;
     background-color: #2255FF;
     border: solid 1px #0022AA;
     color: white;
     padding-left: 5px;
     padding-top: 2px;
     width: 30px;
     height: 23px;
     margin-bottom: -20px;
  }
  
  & .article
  {

    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    
    &__img {
      width: 150px;
      height: 100px;
      margin-right: 10px;
      margin-left: 3px;
      text-align: center;

      & img
      {
        max-width: 100%;
        max-height: 100%;
        border: none;
      }
    }

    &__desc {
    }
  }
  
  & a
  {
    text-decoration: none;
  }
  
  & h2
  {
    margin: 0;
    font-weight: normal;
    color: #FFFFAA;
    font-size: 1.28em;
  }
  
  & .click
  {
    color: #FFFF00;
    margin-top: 1rem;
    margin-right: 1rem;
    text-align: right;
  }

  & .more p {
    padding: .3rem .9rem;
  }
  
  & p
  {
      margin: 0px;
  }
  
  & .date
  {
    color: #55AAFF;
  }
  
  & .summary
  {
      margin-top: 10px;
      font-size: 1.1em;
      padding: .2rem .9rem;
  }
  
  @media screen and (max-width: 547px) {
    margin: 5rem 1rem;

    & .section {
      min-width: 200px;
      border-width: 1px;

      &:hover {
        border-width: 1px;
      }
    }
  }
}
