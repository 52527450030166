/* Eléments principaux de la page */

h1#article-title {
  font-size: 2.5em;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 50px;
}

.article-section {
  margin: 1rem 0rem;
    background: rgba(200,200,255,.1);
    min-width: 400px;
    // border: 3px solid #000024;
}

.sport-article h2
{
  color: transparent;
  height: 0px;
}

.sport-article, .sport-article:hover
{
  border: none;
}

p.summary
{
  margin-bottom: 30px;
}

.more
{
  font-size: 1.3em;
}

.more p
{
  margin: 10px;
  font-family: "Segoe Print";
}

.more a
{
  color: #55AAFF;
}


@media screen and (max-width: 547px) {
  .article-section {
    padding: .5rem;
    margin: 1rem;
    min-width: 200px;
    margin-bottom: 5rem;

    & img {
      width: 300px;
    }

    & p {
      padding: .5rem;
    }
  }
}