#videos {
  margin: 3rem;
  margin-top: 5rem;

  & .video
  {
    border: 3px solid #000024;
    background-color: rgba(200,200,255,.1);
    margin: 3rem 0;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      border: 3px solid rgba(200,200,255,.1);
    }
  }

  & a {
    text-decoration: none;
  }

  & .video iframe
  {
    float: left;
    height: 315px;
    margin-right: 20px;
  }
  
  & .video h2
  {
    color: #FFFFAA;
  }
  
  @media screen and (max-width: 547px) {
    margin: 5rem 1rem;

    & .section {
      min-width: 200px;
    }

    & .video {
      padding: .5rem;
      border-width: 1px;

      &:hover {
        border-width: 1px;
      }
    }
  }
}
