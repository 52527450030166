nav {
    background: rgba(20,20,55,.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    & .navbar {
        display: flex;
        justify-content: space-between;
    
        & a {
            text-decoration: none;
        }
    
        & .icon-bar {
            background-color: white;
            &+.icon-bar {
                margin-top: 4px;
            }
        }

        & .navbar-toggle {
            position: relative;
            float: right;
            // padding: 9px 10px;
            margin-top: 3px;
            margin-right: 15px;
            margin-bottom: 8px;
            background-color: transparent;
            background-image: none;
            border: 1px solid transparent;
            border-radius: 4px;
            display: none;
            cursor: pointer;

            & .icon-bar {
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
            }
        }
    }
    
    & .navbar .navbar-header {
        // background: blue;
        padding: 1rem;

        & a.home {
            color: #FFFFAA;
            font-weight: bold;
            font-size: 1.3em;
        }
    }
    
    & .navbar ul {
        // flex: 1 1 auto;
        // background: green;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        padding: 1rem;
    
        & li {
            margin: 0rem 1rem;
            & a {
                color: #AAA;
                font-weight: bold;
                &:hover, :active, &.selected {
                    color: #FFFFAA;
                }    
                &.color-red {
                    color: red;
                }
            }
        }
    }

    @media screen and (max-width: 547px) {
        & .navbar {
            display: flex;
            flex-wrap: wrap;
            // background: blue;
            & ul {
                // background: green;
                flex-wrap: wrap;
                & li {
                    width: 200px;
                    padding: .5rem;
                    font-size: 1.2em;
                }
            }

            & .hidden {
                display: none;
            }

            & .navbar-toggle {
                display: block;
            }
        }
    }
}
