/* Eléments principaux de la page */

#calendar {

  color: white;
  font-size: .8em;
  margin: 3rem;
  margin-top: 5rem;
  padding: 0rem;

  & .section {
    margin: 1rem 0rem;
    background: rgba(200,200,255,.1);
    min-width: 400px;
    border: 3px solid #000024;

    &:hover
    {
      border: 3px ridge rgba(200,200,255,.2);
    }
  }

  & .article
  {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    
    &__img {
      width: 150px;
      height: 80px;
      margin-right: 20px;
      text-align: center;
    }

    &__desc {
      width: 100%;
    }

    & img
    {
      // float: left;
      // border: none;
      max-width: 150px;
      max-height: 100%;
      // margin-left: 3px;
    }

  }
  
  & a
  {
    text-decoration: none;
  }
  
  & h2
  {
    margin: 0;
    font-weight: normal;
    color: #FFFFAA;
    font-size: 1.28em;
  }
  
  & .click
  {
    color: #FFFF00;
    margin-top: 1rem;
    margin-right: 1rem;
    text-align: right;
  }
  
  & p
  {
      margin: 0px;
  }
  
  & .date
  {
    color: #55AAFF;
  }
  
  & .summary
  {
      margin-top: 10px;
      font-size: 1.1em;
  }
  
  & .new
  {
     display: block;
     position: relative;
     background-color: #2255FF;
     color: white;
     padding-left: 5px;
     padding-top: 2px;
     width: 30px;
     height: 23px;
     border: solid 1px #0022AA;
     left: 1px;
     top: 10px;
  }

  @media screen and (max-width: 547px) {
    margin: 5rem 1rem;;

    & .section {
      min-width: 200px;
      border-width: 1px;

      &:hover {
        border-width: 1px;
      }
    }
  }
}
